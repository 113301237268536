import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';
import {
  computed
} from '@ember/object';
import { sortArray } from '../../../helper-functions/array';

const setPos = (i, pos) => {
  if(!i.get('pos')){
    const p =  pos.get('value')
    i.set('pos', p)
    pos.set('value', p+1)
  }
}

const setConnection = (i, j, con) => {
  if(!j.get('wishesSet')){
    const c =  con.get('value')
    i.get('connections').addObject(c)
    j.get('connections').addObject(c)
    con.set('value', c+1)
  }
}

const setPosAndWishes = (i, pos, con, arr) => {
  setPos(i, pos)
  if(!i.get('wishesSet')){
    i.set('wishesSet', true)
    const wishes = i.get('partnerwunsche').map(j => arr.findBy('termAnmeldung.id', j.get('id')))
    wishes.forEach(j => setPos(j, pos))
    wishes.forEach(j => setConnection(i, j, con))
    wishes.forEach(j => setPosAndWishes(j, pos, con, arr))
  }
}

export default Controller.extend({
  relevantTermAnmeldung: computed('model.termAnmeldung.value.@each.anmeldungen', 'model.events.value.[]', function(){
    const events = this.get('model.events.value')
    if(events){
      const tas = this.get('model.termAnmeldung.value')
      if(tas){
        const eventIds = events.mapBy('id')
        return tas.filter(ta => {
          const an = ta.get('anmeldungen').filter(a => eventIds.includes(a.belongsTo('event').id()))
          return an.get('length') > 1 && an.any(a => a.get('status') === 'included')
        })
      }
    }
    return []
  }),
  orderedRelevantTermAnmeldungWithConnections: computed('relevantTermAnmeldung.@each.optimizerTempIgnore', function(){
    const rtas = this.get('relevantTermAnmeldung')
    const rtasWithWisches = rtas.map(i => {
      const partnerwunsche = i.get('anmeldungen').map(y => y.get('partnerWishes').filter(j => rtas.mapBy('id').includes(j.get('termAnmeldung.id'))).mapBy('termAnmeldung')).reduce((previousValue, item) => previousValue.pushObjects(item),[]).uniqBy('id')
      return Ember.Object.create({
        termAnmeldung: i,
        partnerwunsche,
        random: i._random,
        hasPartnerwunsch: partnerwunsche.get('length') > 0,
        connections: [],
        optimizerTempIgnore: i.optimizerTempIgnore === true
      })
    })
    const rtasWithWischesSorted = sortArray(rtasWithWisches, ['hasPartnerwunsch:desc', 'random:desc'])
    const pos = Ember.Object.create({value: 1})
    const conection = Ember.Object.create({value: 1})
    rtasWithWischesSorted.forEach(i => {
      setPosAndWishes(i, pos, conection, rtasWithWischesSorted)
    })
    return sortArray(rtasWithWischesSorted, ['optimizerTempIgnore','pos'])
  }),
  allAnmeldungenOfRelevantTermAnmeldung: computed('relevantTermAnmeldung.@each.anmeldungen', function(){
    const relevantTermAnmeldung = this.get('relevantTermAnmeldung')
    const anmel = relevantTermAnmeldung.map(ta => ta.get('anmeldungen'));
    return anmel.reduce((summation, current) => {
      if(current){
        return summation.pushObjects(current.toArray());
      }
      return summation
    }, [])
  }),
  relevantAnmeldungen: computed('allAnmeldungenOfRelevantTermAnmeldung.@each.eventID', 'model.events.value.@each.id', function(){
    const events = this.get('model.events.value')
    if(events){
      const anmeldungen = this.get('allAnmeldungenOfRelevantTermAnmeldung')
      return anmeldungen.filter(a => {
        return events.any(e => a.get('eventID') === e.get('id'))
      })
    }
  }),
  globalResetAll: task(function* () {
    const term = yield this.get('model.term')
    const termAnmeldungen = yield term.get('termAnmeldungen')
    termAnmeldungen.forEach(a => a.set('optimizerTempIgnore', false))
    const anmeldungen = yield term.get('anmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  globalResetStatus: task(function* () {
    const term = yield this.get('model.term')
    const anmeldungen = yield term.get('anmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  localResetAll: task(function* () {
    const termAnmeldungen = this.get('relevantTermAnmeldung')
    termAnmeldungen.forEach(a => a.set('optimizerTempIgnore', false))
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  localResetStatus: task(function* () {
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => a.set('_optimizerTempStatus', null))
  }),
  applyChanges: task(function* () {
    const anmeldungen = this.get('relevantAnmeldungen')
    anmeldungen.forEach(a => {
      if(a.get('tempStatusPlus')){
        a.set('status', 'included')
        a.set('_optimizerTempStatus', null)
      }else if(a.get('tempStatusMinus')){
        a.set('status', 'toBeDetermind')
        a.set('_optimizerTempStatus', null)
      }
    })
  }),

});
