import Controller from '@ember/controller';
import {
  computed
} from '@ember/object';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({
    filterPartnerWishes: '',
    queryParams: ['queryPartnerWishes', 'filterPartnerWishes'],
    searchProperties: ['vorname', 'nachname'],
});
