import Component from '@ember/component';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  toggleWish: task(function* (wish) {
      wish = yield wish;
      const anmeldung = yield this.get('anmeldung')
      const selected = anmeldung.get('partnerWishes');
      if(selected.includes(wish)){
        selected.removeObject(wish);
      }else{
        selected.pushObject(wish);
        //anmeldung.set('partnerWishStatus', 'prepared')
      }
      yield anmeldung.save()
      yield wish.save()
    }),
    bestaetigen: task(function* () {
      const anmeldung = yield this.get('anmeldung')
      anmeldung.set('partnerWishStatus', 'prepared')
      this.get('onClose')()
    }),
});
