import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
    computed
} from '@ember/object';
import { filter, filterBy, alias, union, sort, max, min, mapBy } from '@ember/object/computed';
import moment from 'moment';
import {
    inject
  } from '@ember/service';
  import {
    task
  } from 'ember-concurrency';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
    apiFunctions: inject(),
    automatedAllocation: inject(),
    term: DS.belongsTo('term', { async: true, inverse: 'events' }),
    datum: DS.attr('fire-date'),
    titel: DS.attr('string'),
    ort: DS.attr('string'),
    zeit: DS.attr('string'),
    anzahltage: DS.attr('number'),
    beschreibung: DS.attr('string'),
    akid: DS.attr('string'),
    typ: DS.attr('number'),
    maxpersonen: DS.attr('number'),
    anmeldebeginn: DS.attr('fire-date'),
    anmeldeschluss: DS.attr('fire-date'),
    abmeldeschluss: DS.attr('fire-date'),
    warteliste: DS.attr('string'),
    userid: DS.attr('string'),
    kontakt:  DS.attr('string'),
    key_Veranstaltungstyp: DS.attr('string'),
    key_Art: DS.attr('string'),
    key_Niveau: DS.attr('string'),
    key_Fun_Leistung: DS.attr('string'),
    niveau: DS.belongsTo('niveau', { async: true }),
    status: DS.attr('string'), // canceled
    statusTemp: DS.attr('string'),
    notiz: DS.attr('string'),
    todos: DS.hasMany('todos', { async: true, inverse: 'event' }),
    oeffentlich: DS.attr('string'),
    oeffentlich_lock: DS.attr('string'),
    vfarbe: DS.attr('string'),
    vfarbe_lock: DS.attr('string'),
    hfarbe: DS.attr('string'),
    hfarbe_lock: DS.attr('string'),
    onHold: DS.attr('boolean'),
    anmeldungen: DS.hasMany('anmeldung', { async: true, inverse: 'event' }),
    trainer: DS.hasMany('teilnehmer', { async: true, inverse: 'eventsAlsTrainer' }),
    sortedAnmeldungen: sort('anmeldungen', 'automatedAllocation.prioSort'),
    statusCalc: computed('statusTemp', 'status', 'todoStatus', 'shouldBeClosed', 'anmeldungen.@each.{status,toConfirm}', 'includedAnmeldungenWithMoreThanOneIncluction.length', 'excludedAnmeldungenWithZeroIncluctionsChangable.length', function(){
        if(this.get('statusTemp') === 'canceled' || this.get('status') === 'canceled'){
            return 'canceled'
        }else if(this.get('includedAnmeldungenWithMoreThanOneIncluction.length') > 0 && this.get('excludedAnmeldungenWithZeroIncluctionsChangable.length')>0){
            return 'possible-optimization'
        }else if(this.get('todoStatus') === 'Todo'){
            return 'todo'
        }else if(this.get('todoStatus') === 'Waiting'){
            return 'todo-waiting'
        }else if(this.get('anmeldungen').any(a => a.status === 'toBeDetermind') || this.get('anmeldungen.length') === 0){
            return 'to-allocate'
        }else if(this.get('anmeldungen').any(a => a.toConfirm)){
            return 'to-confirm'
        }else if(this.get('shouldBeClosed')){
            return 'to-close'
        }else{
            return 'completed'
        }
    }),
    isCanceled: computed('statusCalc', function(){
        return ['canceled'].includes(this.get('statusCalc'))
    }),
    isTodo: computed('statusCalc', function(){
        return ['possible-optimization', 'todo', 'todo-waiting', 'to-allocate', 'to-confirm', 'to-close', 'possible-optimization'].includes(this.get('statusCalc'))
    }),
    statusOrder: computed('statusCalc','includedAndToBeDetermindAnmeldungenLength', 'maxpersonen', 'term.durchMin', function(){
        if(this.get('statusCalc') === 'to-allocate'){
            const anzahlTeilnehmer = this.get('includedAndToBeDetermindAnmeldungenLength')
            const maxpersonen = this.get('maxpersonen')
            const durchMin = this.get('term.durchMin')
            const belegung = anzahlTeilnehmer/maxpersonen
            const minBelegung = durchMin/100
            if(belegung < minBelegung){
                return 2
            }else if(belegung > 1){
                return 1
            }else{
                return (belegung - minBelegung) * (99/(100-durchMin))
            }
        }else{
            const order = ['to-allocate', 'to-allocate-2', 'to-allocate-3', 'possible-optimization', 'todo', 'to-confirm', 'to-close', 'todo-waiting','completed', 'canceled'];
            return order.indexOf(this.get('statusCalc'));
        }
    }),
    artAndNiveau: computed('key_Art', 'key_Niveau', 'key_Fun_Leistung', function(){
        const niveau = (this.get('key_Niveau') && this.get('key_Niveau') !== "") ? "-"+this.get('key_Niveau') : "";
        const funLeistung = (this.get('key_Fun_Leistung') && this.get('key_Fun_Leistung') !== "") ? "-"+this.get('key_Fun_Leistung') : "";
        return this.get('key_Art')+niveau+funLeistung
    }),
    includedAnmeldungenOhneToLate: filterBy('anmeldungen', 'statusOhneToLate', 'included'),
    includedAnmeldungen: filterBy('anmeldungen', 'status', 'included'),
    includedAnmeldungenFrau: filterBy('includedAnmeldungen', 'gender', 'Frau'),
    includedAnmeldungenHerr: filterBy('includedAnmeldungen', 'gender', 'Mann'),
    includedAnmeldungenRest: filterBy('includedAnmeldungen', 'gender', '*'),
    includedAnmeldungenProv: filterBy('includedAnmeldungen', 'absageGrund', 'temporary'),
    includedAnmeldungenProvLength: alias('includedAnmeldungenProv.length'),
    includedAnmeldungenWithMoreThanOneIncluction: filter('includedAnmeldungen.@each.{lengthIncludedAnmeldungen,lock}', function(anmeldung, index, array) {
        return anmeldung.get('lengthIncludedAnmeldungen') > 1 && !anmeldung.get('lock');
    }),
    excludedAnmeldungen: computed('anmeldungen.@each.{status,absageGrund}', function(){
        return this.get('anmeldungen').filterBy('status', 'excluded');
    }),
    excludedAnmeldungenWithoutWish: computed('excludedAnmeldungen.@each.absageGrund', function(){
        return this.get('excludedAnmeldungen').rejectBy('absageGrund', 'wish').rejectBy('absageGrund', 'wish-maxnofevents');
    }),
    excludedAnmeldungenWithZeroIncluctionsChangable: filter('excludedAnmeldungen.@each.{lengthIncludedAnmeldungen,lock,isLuck}', function(anmeldung, index, array) {
        return anmeldung.get('lengthIncludedAnmeldungen') == 0 && !anmeldung.get('lock') && anmeldung.get('isLuck') && !anmeldung.get('isNiveauTodoMaybe')
    }),
    absagenWithoutWishErrorAndNiveau: computed('excludedAnmeldungenWithoutWish.@each.absageGrund', function(){
        return this.get('excludedAnmeldungenWithoutWish').rejectBy('absageGrund', 'error').rejectBy('absageGrund', 'niveau').rejectBy('absageGrund', 'noniveau');
    }),
    toBeDetermindAnmeldungen: filterBy('anmeldungen', 'status', 'toBeDetermind'),
    toBeDetermindAnmeldungenRoundOne: filterBy('toBeDetermindAnmeldungen', 'runde', 1),
    toBeDetermindAnmeldungenFrau: filterBy('toBeDetermindAnmeldungen', 'gender', 'Frau'),
    toBeDetermindAnmeldungenHerr: filterBy('toBeDetermindAnmeldungen', 'gender', 'Mann'),
    toBeDetermindAnmeldungenRest: filterBy('toBeDetermindAnmeldungen', 'gender', '*'),
    anmeldungenWithPartnerWish:  filterBy('anmeldungen', 'hasPartnerWish', true),
    anmeldungenWithOutPartnerWish:  filterBy('anmeldungen', 'hasPartnerWish', false),
    spacesLeftOhneToLate: computed('includedAnmeldungenOhneToLate.length', 'maxpersonen', 'status', function(){
        if(this.get('status') == 'canceled'){
            return 0;
        }else{
            return this.get('maxpersonen') - this.get('includedAnmeldungenOhneToLate.length');
        }
    }),
    spacesLeft: computed('includedAnmeldungen.length', 'maxpersonen', 'status', function(){
        if(this.get('status') == 'canceled'){
            return 0;
        }else{
            return this.get('maxpersonen') - this.get('includedAnmeldungen.length');
        }
    }),
    includedAndToBeDetermindAnmeldungen: union('includedAnmeldungen', 'toBeDetermindAnmeldungen'),
    includedAndToBeDetermindAnmeldungenLength: alias('includedAndToBeDetermindAnmeldungen.length'),
    spacesLeftWithoutCandidate: computed('includedAndToBeDetermindAnmeldungen.length', 'maxpersonen', function(){
        return Math.max(0, this.get('maxpersonen') - this.get('includedAndToBeDetermindAnmeldungen.length'));
    }),
    missingSpaces: computed('toBeDetermindAnmeldungen.@each.weightedAnmeldungNotIncluded', 'absagenWithoutWishErrorAndNiveau.@each.weightedAnmeldungNotIncluded', 'spacesLeft', function(){
        const tbd = Math.round(this.get('toBeDetermindAnmeldungen').reduce((previousValue, item) => previousValue+item.get('weightedAnmeldungNotIncluded'), 0));
        const abs = Math.round(this.get('absagenWithoutWishErrorAndNiveau').reduce((previousValue, item) => previousValue+item.get('weightedAnmeldungNotIncluded'), 0));
        return Math.max(0, tbd+abs-this.get('spacesLeft'));
    }),
    tag: computed('datum', function(){
        return moment(this.get('datum')).format("dd");
    }),
    toBeDetermindAnmeldungenLength: alias('toBeDetermindAnmeldungen.length'),
    confirmedAnmeldungen: filterBy('anmeldungen', 'isConfirmed', true),
    hasAtleastOneConfirmation: computed('confirmedAnmeldungen.length', function(){
        return this.get('confirmedAnmeldungen.length') > 0
    }),
    isOpenAndDoesntHaveConfirmations: computed('statusCalc', 'hasAtleastOneConfirmation', function(){
        if(this.get('statusCalc') === 'canceled') return false
        if(this.get('hasAtleastOneConfirmation')) return false
        return true
    }),
    activityLevelOfInflucdeAnmeldung: mapBy('includedAnmeldungen', 'aktivenStatus'),
    highestActivityLevelOfInflucdeAnmeldung: max('activityLevelOfInflucdeAnmeldung'),
    lowestActivityLevelOfInflucdeAnmeldung: min('activityLevelOfInflucdeAnmeldung'),
    termAnmeldeschluss: alias('term.anmeldeschluss'),
    href: computed('id', function(){
        return 'https://www.vereinonline.org/BeachL_eV/?veranstaltung='+this.get('id')
    }),
    zugelasseneEinschaetzungen: alias('niveau.zugelasseneEinschaetzungen'),
    registrationClosed: computed('anmeldeschluss', 'oeffentlich', function(){ //TODO with anmeldeschluss & not only 2
        return this.get('oeffentlich') === "2"
    }),
    shouldBeClosed: computed('spacesLeft', 'registrationClosed', 'status', function(){
        return this.get('spacesLeft') <= 0 && !this.get('registrationClosed') && this.get('status') !== 'canceled'
    }),
    todosWithAnmeldungen: computed('todos.[]', 'anmeldungen.@each.todos', function(){
        let out = [];
        out = out.pushObjects(this.get('todos').toArray())
        this.get('anmeldungen').forEach(i => {
            out = out.pushObjects(i.get('todos').toArray())
        })
        return out
    }),
    todoStatus: computed('todosWithAnmeldungen.@each.status', function(){
        if(this.get('todosWithAnmeldungen').isAny('status', 'Todo')){
            return 'Todo'
        }else if(this.get('todosWithAnmeldungen').isAny('status', 'Waiting')){
            return 'Waiting'
        }else if(this.get('todosWithAnmeldungen').isAny('status', 'Done')){
            return 'Done'
        }else{
            return 'None'
        }
    }),
    hasNiveauEinschaetzungen: computed('includedAnmeldungen.@each.hasNiveauEinschaetzung', function(){
        const anzahl = this.get('includedAnmeldungen.length')
        const anzahlCompleted = this.get('includedAnmeldungen').filterBy('hasNiveauEinschaetzung').get('length')
        if(anzahl === 0 || anzahlCompleted === 0){
            return 'open'
        }else if(anzahl === anzahlCompleted){
            return 'done'
        }else{
            return 'parcial'
        }
    }),
    tempStatusMinus: computed('anmeldungen.@each.tempStatusMinus', function(){
        return this.get('anmeldungen').filterBy('tempStatusMinus').get('length')
    }),
    tempStatusPlus: computed('anmeldungen.@each.tempStatusMinus', function(){
        return this.get('anmeldungen').filterBy('tempStatusPlus').get('length')
    }),
    closeEvent: task(function* () {
        this.set('vfarbe_lock', this.get('vfarbe'))
        this.set('hfarbe_lock', this.get('hfarbe'))
        this.set('oeffentlich_lock', this.get('oeffentlich'))
        yield this.save()
        yield this.get('apiFunctions').closeEvent.perform(this.get('id'))
    }).drop(),
    openEvent: task(function* () {
        const oeffentlich = (this.get('oeffentlich_lock') && this.get('oeffentlich_lock') !== "") ? this.get('oeffentlich_lock') : "3";
        const vfarbe = (this.get('vfarbe_lock') && this.get('vfarbe_lock') !== "") ? this.get('vfarbe_lock') : "#000000";
        const hfarbe = (this.get('hfarbe_lock') && this.get('hfarbe_lock') !== "") ? this.get('hfarbe_lock') : "#ffffff";
        yield this.get('apiFunctions').updateEvent.perform(this.get('id'), oeffentlich, vfarbe, hfarbe)
    }).drop(),
})
